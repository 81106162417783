
.training-table{
    margin: 1% 20%;
}
.training-table-header-section {
    height: 20px;
    align-items: center;
    background: #09193c;
    border-bottom: 1px solid #EFEFEF;
    cursor: pointer;
    color:#EFEFEF;
    text-align: left;
    padding: 3px 12px;
}
.table-message-text {
    overflow-y: auto;
    word-break: break-word;
    font-size: 14px;
    white-space: pre-wrap;
    text-align: left;
    line-height: 1.6;
    color: #255d6a;
    /*font-family: 'Aptos';*/
    font-weight: 400;
}
.training-table-body-tab{
    text-align: left;
    padding: 12px;
}
.training-header{
    font-size: 24px;
    margin: 2px ;
    /*font-family: 'Aptos Bold';*/
    color: #09193c;
    font-weight: 600;
}
.training-atag:visited{
    color: #255d6a;
    /*font-family: 'Aptos Bold';*/
    font-size: 14px;
    font-weight: 600;
}
.training-content{
    display: flex;
    justify-content: center;
}