.pubmed-main-innerWrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-bottom: 1%;
    background: linear-gradient(248.92deg, #c1d0d8 27.73%, #ebebeb);
}

.pubmed-main-section-container {
    width: 100%;
    min-height: calc(100% - 90px);
    padding: 0 20px;
}

.pubmed-main-ask-question-wrapper {
    height: 100%;
    width: 100%;
}

.pubmed-main-input-box-wrapper {
    position: absolute;
    display: inline-block;
    width: 865px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 24px;
    box-shadow: 0px 3px 6px #00000029;
}

.pubmed-main-search-input-wrapper {
    padding: 1% 0 0 0;
    font-family: 'J&J CircularTT-Medium';
    display: flex;
    justify-content: center;
}

.pubmed-main-input-search-box {
    width: 805px;
    height: 64px;
    font-family: 'J&J CircularTT-Medium';
    outline: none;
    font-size: 1.5rem;
    line-height: 2rem;
    float: left;
    position: relative;
    color: #333;
    opacity: 1;
    padding: 16px;
    letter-spacing: 0.02px;
    border: none;
    border-radius: 24px;
}

.pubmed-main-input-search-box::placeholder {
    /* color: #291d1de1; */
}

.pubmed-main-input-search-box:focus {
    border: 2px solid #e86161;
}

.pubmed-main-icon-wrapper {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    right: 0;
    top: 0;
    width: 60px;
    background-color: transparent;
    padding: 0;
    height: 64px;

}

.pubmed-main-input-search-cancel-icon {
    cursor: pointer;
    width: 15px;
    height: 15px;
    margin: 0 6%;
}


.pubmed-main-search-icon-wrapper-webView {
    cursor: pointer;
    color: #e86161;
    opacity: 1;
    padding: 5%;
    height: 100%;
    margin-left: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;

}

.pubmed-main-search-icon-wrapper-webView:hover,
.pubmed-main-left-textarea-label-clear:hover,
.pubmed-main-left-search-button:hover {
    opacity: 0.8;
    cursor: pointer;
}

.pubmed-main-magnifyingGlass-mobileView {
    width: 65%;
}

.box-white {
    background: #fff;
    box-shadow: 0 0 34px rgba(12, 63, 94, .12);
    /* border-radius: 20px; */
    padding: .9rem;
}

.pubmed-main-left-textarea-box {
    height: 60px;
    font-weight: 400;
    font-size: 0.875em;
    resize: none;
    width: 100%;
    border: none;
    background-color: #f2f5f7;
}

.pubmed-main-left-search-button {
    padding-left: 1rem;
    padding-right: 1rem;
    font-weight: 400;
    border-radius: 0.25rem;
    gap: .5rem;
    height: 2.5rem;
    min-width: 5rem;
    background: #e86161;
    color: #fff;
    display: flex;
    align-items: center;
}

.pubmed-main-section {
    margin-top: 1.25rem;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    height: 100%;
    display: flex;
}

.pubmed-main-left-section {
    width: 330px;
    margin-right: 10px;
}

.pubmed-main-right-section {
    margin-left: 10px;
    width: calc(100% - 350px);
}

.pubmed-main-left-search-button-section {
    display: flex;
    justify-content: flex-end;
    margin-top: .75rem;
}

.pubmed-main-left-textarea-label-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

}

.pubmed-main-left-textarea-section {
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: .75rem;
    padding-right: .75rem;
    border-width: 1px;
    border-color: #e1e9ef;
    background-color: #f2f5f7;
    /* border-radius: 1.25rem; */
    box-shadow: var(0 0 #0000, 0 0 #0000), var(0 0 #0000, 0 0 #0000), var(0 0 #0000);
}

.pubmed-main-left-textarea-label-section>span {
    color: #52525b;
    font-size: 0.875rem;
}

.font-bold {
    font-weight: 700;
}


.pubmed-main-left-filter {
    margin-top: 20px;
    min-height: 400px;
}

.pubmed-main-left-filter-title {
    text-align: left;
}

.box {
    border-radius: 1.5rem;
    background-color: rgb(255 255 255 / .5);
    padding: 1.25rem;
    /* --tw-shadow: 0px 0px 34px rgba(12, 63, 94, 0.12);
    --tw-shadow-colored: 0px 0px 34px var(--tw-shadow-color); */
    box-shadow: var(0 0 #0000, 0 0 #0000), var(0 0 #0000, 0 0 #0000), var(0px 0px 34px #0c3f5e1f);
}

.pubmed-main-right-answer {
    padding-top: .75rem;
    padding-bottom: .75rem;
    overflow-y: auto;
    max-height: 400px;
    margin-bottom: 1rem;
    position: relative;
    text-align: left;
}

.text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}

.m-0 {
    margin: 0;
}

.pubmed-main-right-answer>h2 {
    font-weight: 600;
}

.pubmed-main-filterMenuWrapperExpanded {
    width: 100%;
}

.pubmed-main-left-section-filter-contents-wrapper {

    display: flex;
    margin-top: 30px;
}

.pubmed-main-left-section-filter-menu-content {
    color: var(--dark-grey);
    border: solid 1px #dcdcdc;
    min-height: 44vh;
    background: #fff;
}

.pubmed-main-filterMenuWrapperCollapse {
    height: 42px;
}

.pubmed-main-ask-question-headerWithBanner {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.pubmed-main-ask-question-headerWithBanner-right {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.pubmed-main-ask-question-headerWithBanner-pubmedicon-wrapper {
    padding-left: 5px;
}

.pubmed-main-ask-question-headerWithBanner-pubmednih-wrapper {
    padding-left: 5px;
}

.pubmed-main-ask-question-headerWithBanner-pubmedicon-wrapper>.global-feature-pubmedicon {
    height: 40px;
}

.pubmed-main-ask-question-headerWithBanner-pubmednih-wrapper>img {
    height: 40px;
}
.pubmed-main-filterMenuWrapperCollapse{
    width: auto;
}
.pubmed-main-filterMenuWrapperCollapse > .result-filter-expand-wrapper{
    padding: 10px;
    background: #2a498a;
    height:auto;
    border: solid 1px #dcdcdc;
}
.pubmed-main-filterMenuWrapperCollapse > .result-filter-expand-wrapper img {
    border: none; 
     padding: 7%; 
     background:none;
}
@media screen and (min-width: 1000px) and (max-width: 1200px) {}

@media screen and (min-width: 1000px) and (max-width: 1100px) {
    .pubmed-main-input-box-wrapper {
        width: 44%;
    }
}

@media screen and (min-width: 1101px) and (max-width: 1200px) {
    .pubmed-main-input-box-wrapper {
        width: 47.5%;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {
    .pubmed-main-input-box-wrapper {
        width: 47.5%;
    }
}

@media screen and (min-width: 1301px) and (max-width: 1400px) {
    .pubmed-main-input-box-wrapper {
        width: 47.5%;
    }
}


@media screen and (min-width: 1000px) and (max-width: 1024px) {
    .pubmed-main-input-box-wrapper {
        width: 765px;
    }

    .pubmed-main-input-search-box {
        width: 705px;
    }

    .pubmed-main-input-search-box {
        font-size: 16px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1281px) {
    .pubmed-main-input-box-wrapper {
        width: 865px;
    }

    .pubmed-main-input-search-box {
        width: 805px;
    }
}

@media screen and (min-width: 1282px) {
    .pubmed-main-input-box-wrapper {
        width: 1000px;
    }

    .pubmed-main-input-search-box {
        width: 940px;
    }

}