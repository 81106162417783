.footercontent-mobile-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1% 2%;
}
.footerImg-mobile{
    padding:1% 0;
    width:100%;
    padding-bottom:1%;
}
.footerLinks-mobile{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin:0 !important;
}
.footerLinks{
    float:right;
    color: #808080;
    font-size: 14px;
    line-height: 1.42857143;
    font-family: 'J&J CircularTT-Medium' !important;
    box-sizing: border-box;
    margin:0% -4%;
  }
  .footerLinks a{
    color: #0a58ca;
    text-decoration: none;
  }
  .footerLinks a:visited {
    color: #0a58ca !important;
  }
  .footerLinks a:hover{
    text-decoration: underline;
  }
  .footerText-mobile{
      text-align: center;
      width: 100%;
      padding:1% 0;
      font-size: 12px;
  }
  .footercontent-tablet-wrapper{
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  .footerText-tablet-wrapper{
      font-size: 13px;
  }
  .footerLinks-tablet-wrapper{
      font-size: 13px;
  }